import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import ReleaseNote from "./ReleaseNote.vue";
import { mapGetters } from "vuex";
export default {
  name: "ReleaseNoteHistory",
  components: {
    ReleaseNote
  },
  emits: ["close"],
  data() {
    return {
      betaFeatureToDisplay: null,
      features: {},
      showFullReportAt: null
    };
  },
  computed: {
    ...mapGetters({
      betaFeatureList: "platform/betaFeatures",
      showBetaHistory: "layout/betaFeatureHistory"
    }),
    featureNames() {
      return Object.keys(this.features);
    },
    historyToDisplay() {
      return this.features[this.betaFeatureToDisplay];
    }
  },
  beforeMount() {
    this.betaFeatureList.forEach(feature => {
      const history = [...feature.update_history.history];
      this.features[feature.feature_name] = history.reverse();
    });
    this.betaFeatureToDisplay = this.featureNames[0];
  },
  methods: {
    displayFullReport(event) {
      if (this.showFullReportAt === event.position) {
        this.showFullReportAt = null;
        return;
      }
      this.showFullReportAt = event.position;
    }
  }
};