import ArrowIcon from "@/Assets/images/ArrowIcon.vue";
export default {
  name: "ReleaseNote",
  components: {
    ArrowIcon
  },
  emits: ["toggleReport"],
  props: {
    position: {
      type: Number,
      default: 0
    },
    note: {
      type: Object,
      default() {
        return {
          datetime: "2024-01-26 18:14:09",
          title: {
            en: "Test Title",
            fr: "Test Title"
          },
          message: {
            en: "Test Description",
            fr: "Test Description"
          }
        };
      }
    },
    showFullReport: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hasMoreInformation: false
    };
  },
  computed: {
    language() {
      return localStorage.getItem("locale");
    }
  },
  mounted() {
    this.afterUpdateAssesReport();
  },
  methods: {
    afterUpdateAssesReport() {
      this.$nextTick(() => {
        this.showMoreVisible();
      });
    },
    showMoreVisible() {
      const report = document.getElementById(`report-${this.position}`);
      if (!report) {
        return 0;
      }
      this.hasMoreInformation = report.scrollHeight > 60;
    },
    toggleReport() {
      if (this.hasMoreInformation) {
        this.$emit("toggleReport", {
          position: this.position
        });
      }
    }
  },
  watch: {
    note: {
      handler() {
        this.afterUpdateAssesReport();
      },
      deep: true
    }
  }
};