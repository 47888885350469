import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/Assets/images/loader-80x80.gif';
const _hoisted_1 = {
  class: "loader-container"
};
const _hoisted_2 = {
  class: "card-body loader-body"
};
const _hoisted_3 = {
  class: "loader-title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("span", null, _toDisplayString(_ctx.$t("platform.general.loading")), 1)]), _cache[0] || (_cache[0] = _createElementVNode("div", {
    class: "loader"
  }, [_createElementVNode("img", {
    id: "loader",
    src: _imports_0,
    alt: "Loader"
  })], -1))])]);
}