import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  width: "14",
  height: "14",
  viewBox: "0 0 14 14",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [_createElementVNode("path", {
    "fill-rule": "evenodd",
    "clip-rule": "evenodd",
    d: "M4.46877 0.530339C4.76167 0.237446 5.23654 0.237446 5.52943 0.530339L8.9741 3.97501C9.26405 4.26496 9.26405 4.73506 8.9741 5.02501C8.68415 5.31496 8.21405 5.31496 7.9241 5.02501L4.9991 2.10001L2.0741 5.02501C1.78415 5.31496 1.31405 5.31496 1.0241 5.02501C0.734153 4.73506 0.734153 4.26496 1.0241 3.97501L4.46877 0.530339Z",
    fill: "#484D56"
  }, null, -1)]));
}