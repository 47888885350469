import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "title"
};
const _hoisted_2 = {
  class: "call-to-action"
};
const _hoisted_3 = {
  class: "text"
};
const _hoisted_4 = {
  class: "icon-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ArrowIcon = _resolveComponent("ArrowIcon");
  return _openBlock(), _createElementBlock("div", {
    class: "card-body",
    id: "release-note-slider",
    onMouseover: _cache[0] || (_cache[0] = (...args) => $options.stopSlideOutTimer && $options.stopSlideOutTimer(...args)),
    onMouseleave: _cache[1] || (_cache[1] = (...args) => $options.startToSlideOut && $options.startToSlideOut(...args)),
    onClick: _cache[2] || (_cache[2] = $event => _ctx.$emit('clicked'))
  }, [_createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("cta.notification")), 1), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("cta.betaUpdates")), 1), _createElementVNode("div", _hoisted_4, [_createVNode(_component_ArrowIcon)])])], 32);
}