import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "release-note"
};
const _hoisted_2 = {
  class: "step-container"
};
const _hoisted_3 = {
  class: "step-number"
};
const _hoisted_4 = {
  key: 0,
  class: "vertical-line"
};
const _hoisted_5 = {
  class: "note-container"
};
const _hoisted_6 = {
  class: "note-information"
};
const _hoisted_7 = {
  class: "date"
};
const _hoisted_8 = {
  class: "title"
};
const _hoisted_9 = ["id"];
const _hoisted_10 = {
  class: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ArrowIcon = _resolveComponent("ArrowIcon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString($props.position), 1), $props.position > 1 ? (_openBlock(), _createElementBlock("div", _hoisted_4)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString($props.note.datetime), 1), _createElementVNode("div", _hoisted_8, _toDisplayString($props.note.title[$options.language]), 1), _createElementVNode("div", {
    class: _normalizeClass(["report", {
      'ellipsis-text': $data.hasMoreInformation && !$props.showFullReport
    }]),
    id: 'report-' + $props.position
  }, _toDisplayString($props.note.message[$options.language]), 11, _hoisted_9), $data.hasMoreInformation ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "more-information",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.toggleReport && $options.toggleReport(...args))
  }, [_createElementVNode("div", _hoisted_10, _toDisplayString($props.showFullReport ? _ctx.$t("cta.showLess") : _ctx.$t("cta.showMore")), 1), _createElementVNode("div", {
    class: _normalizeClass(["icon-container", {
      rotate: !$props.showFullReport
    }])
  }, [_createVNode(_component_ArrowIcon)], 2)])) : _createCommentVNode("", true)])])]);
}