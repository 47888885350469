import ArrowIcon from "@/Assets/images/ArrowIcon.vue";
export default {
  name: "Slider",
  components: {
    ArrowIcon
  },
  emits: ["clicked"],
  data() {
    return {
      timerID: null
    };
  },
  mounted() {
    this.startCountDown("slideIn");
  },
  methods: {
    // Start the countdown
    // If a count down has started clear it and start a new one
    // Timing and functionality set for different actions:
    // - slideIn functionality runs after 0.5secs and makes the component visible
    // - slideOut (default) functionality runs after 5secs and makes the component hidden
    //   it then makes a request to update that the user has seen the component
    startCountDown(direction) {
      // Clear timer if present
      if (this.timerID) {
        clearTimeout(this.timerID);
        this.timerID = null;
      }
      switch (direction) {
        // slideIn action
        case "slideIn":
          this.timerID = setTimeout(() => {
            const banner = document.getElementById("release-note-slider");
            banner.style.right = "22px";
            banner.style.opacity = 1;
            this.startCountDown("slideOut");
          }, 500);
          break;
        // slideOut action
        default:
          this.timerID = setTimeout(async () => {
            const banner = document.getElementById("release-note-slider");
            banner.style.right = "-300px";
            banner.style.opacity = 0;
          }, 10000);
      }
    },
    // Start the count down to slide the notification out of view
    startToSlideOut() {
      this.startCountDown("slideOut");
    },
    // Stop the current set timer ( happens when a user hovers on the component )
    stopSlideOutTimer() {
      clearTimeout(this.timerID);
      this.timerID = null;
    }
  }
};