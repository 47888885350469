import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import { createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  id: "app"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1
};
import appConfig from "@/app.config";
import LoadingCard from "@/Global/Components/loadingCard.vue";
import TourCard from "./Global/Components/KitForUI/TourCard.vue";
import { featureTours } from "./Global/Components/KitForUI/ProductTour/tours.js";
import { computed, nextTick, onBeforeMount, ref, watch, watchEffect } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import NewUpdateInformation from "./Global/Components/KitForUI/NewUpdateInformation";
import Slider from "./Global/Components/KitForUI/BetaReleaseNotes/Slider.vue";
import ReleaseNoteHistory from "./Global/Components/KitForUI/BetaReleaseNotes/ReleaseNoteHistory.vue";
export default {
  __name: 'App',
  setup(__props) {
    const store = useStore();
    const route = useRoute();
    const stopTour = ref(false);
    const showReleaseHistory = ref(false);
    const featureList = computed(() => store.getters["layout/featureList"]);
    const betaFeatureList = computed(() => store.getters["platform/betaFeatures"]);
    const userInfo = computed(() => store.getters["user/userInfo"]);
    const showBetaHistory = computed(() => store.getters["layout/betaFeatureHistory"]);
    const featureTour = computed(() => store.getters["layout/featureTour"]);
    const loading = computed(() => store.getters["layout/loading"]);
    const activeRoute = computed(() => route.path);
    const activeRouteName = computed(() => route.name);
    const newUpdatedShouldBeVisible = computed(() => {
      const pagesWithNewUpdates = ["Home", "Claim Center - Data Needed"];
      return pagesWithNewUpdates.includes(activeRouteName.value);
    });
    const featureRoutes = computed(() => {
      if (!featureList.value) {
        return false;
      }
      return featureList.value.map(feature => feature.page_route);
    });
    const is404Page = computed(() => {
      return route.path === "/404";
    });
    const activateTour = computed(() => {
      if (featureTour.value) {
        return true;
      }
      if (stopTour.value || !userInfo.value.token || !userInfo.value.token.length) {
        return false;
      }
      if (!featureRoutes.value) {
        return false;
      }
      const admin = store.getters["user/isAdmin"];
      if (featureRoutes.value.includes(activeRoute.value)) {
        if (activeRouteName.value in featureTours && !admin) {
          return true;
        }
        return false;
      }
      return false;
    });
    const featureActive = computed(() => {
      return featureList.value.filter(feature => feature.page_route === activeRoute.value)[0];
    });
    watchEffect(() => {
      document.title = appConfig.title;
    });
    onBeforeMount(() => {
      window.addEventListener("beforeunload", event => {
        // Cancel the event as stated by the standard.
        event.preventDefault();
        // Chrome requires returnValue to be set.
        event.returnValue = "";
      });
      const language = localStorage.getItem("locale") || "en";
      const currency = localStorage.getItem("currency") || "EUR";
      localStorage.setItem("currency", currency);
      localStorage.setItem("locale", language);
      hideFeatureHistory();
    });
    const hideFeatureHistory = () => store.dispatch("layout/disengage_feature_history");
    const showFeatureHistory = () => store.dispatch("layout/engage_feature_history");
    const removeFeatureActive = () => {
      stopTour.value = true;
    };
    const releaseHistoryAvailable = () => {
      let condition = false;
      betaFeatureList.value.forEach(({
        update_history
      }) => {
        condition = !!update_history.history.length;
      });
      return condition;
    };
    watch(activeRoute, () => {
      if (stopTour.value) {
        stopTour.value = false;
      }
    });
    watch(betaFeatureList, () => {
      nextTick(() => {
        showReleaseHistory.value = releaseHistoryAvailable();
      });
    }, {
      deep: true
    });
    return (_ctx, _cache) => {
      const _component_RouterView = _resolveComponent("RouterView");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createElementVNode("div", null, [_createVNode(LoadingCard)], 512), [[_vShow, loading.value]]), _withDirectives(_createVNode(_component_RouterView, null, null, 512), [[_vShow, !loading.value]]), activateTour.value && !loading.value ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(TourCard, {
        cards: _unref(featureTours)[activeRouteName.value],
        tour: featureActive.value,
        onTourFinished: removeFeatureActive
      }, null, 8, ["cards", "tour"]), _cache[0] || (_cache[0] = _createElementVNode("div", {
        class: "overlay"
      }, null, -1))])) : _createCommentVNode("", true), newUpdatedShouldBeVisible.value && !loading.value ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_unref(NewUpdateInformation))])) : _createCommentVNode("", true), _withDirectives(_createElementVNode("div", null, [_createVNode(Slider, {
        onClicked: showFeatureHistory
      }), showBetaHistory.value ? (_openBlock(), _createBlock(ReleaseNoteHistory, {
        key: 0,
        onClose: hideFeatureHistory
      })) : _createCommentVNode("", true)], 512), [[_vShow, !loading.value && showReleaseHistory.value && !is404Page.value]])]);
    };
  }
};